<!-- PROGRESS BAR -->
<pristine-progress-bar></pristine-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ngx-spinner
    bdColor="rgba(219, 168, 248, 0.32)"
    size="medium"
    color="#FFB6C1"
    type="ball-spin"
    fullScreen="true"
    template="<img src='/assets/icons/loading-image/loading_new.gif' width='200px' height='200px'/>"
>
<!--    <p style="font-size: 20px; color:rgba(255,182,193,0.22)">Please Wait...</p>-->
</ngx-spinner>

<ng-container *ngIf="pristineConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!--<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"-->
<!--        [ngClass]="{'right-side-panel': pristineConfig.layout.sidepanel.position === 'right',-->
<!--                    'side-panel-hidden': pristineConfig.layout.sidepanel.hidden === true}"-->
<!--        (click)="toggleSidebarOpen('themeOptionsPanel')">-->
<!--    <mat-icon>settings</mat-icon>-->
<!--</button>-->
<!--todo option buton click then sidebar open-->
<!--<pristine-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">-->
<!--    <pristine-theme-options></pristine-theme-options>-->
<!--</pristine-sidebar>-->
<!-- / THEME OPTIONS PANEL -->
