export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'User Menu',
            'Master'  : 'Master',
            'Order'  : 'Order',
            'Setup':'Setup',
            'Dispatch Order'  : 'Dispatch Order',
            'Reports':'Reports',
            'Target Assignment':'Target Assignment',
            'DASHBOARDS'  : 'Dashboards',
            'Goods Return Process':'Goods Return Process',
            'Stock Taking Process':'Stock Taking Process',
            'Gate Entry':'Gate Entry',
            'Barcode Printing':'Barcode Printing',
            'CALENDAR'    : 'Calendar',
            'ECOMMERCE'   : 'E-Commerce',
            'Order Creation':'Order Creation',
            'ACADEMY'     : 'Academy',
            'MAIL'        : {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX'        : {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT'        : 'Chat',
            'FILE_MANAGER': 'File Manager',
            'CONTACTS'    : 'Contacts',
            'TODO'        : 'To-Do',
            'SCRUMBOARD'  : 'Scrumboard'
        }
    }
};
