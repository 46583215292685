import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {GlobalPostingHeader} from "./GlobalPostingHeader";

@Injectable({
    providedIn: 'root'
})
export class WebApiHttp implements OnInit {

    // public globalurl: string = 'https://wmsdemo.pristinefulfil.com';
    // public globalurl: string = 'https://wmsmarketplacedemo.pristinefulfil.com';
    public globalurl: string = 'https://kalyanilive.pristinefulfil.com';

    public ApiUrlArray: any = {
        //todo user
        UserLoginAccess: '/api/User/UserLogin',
        signalRNotification: '/Notification',

        //todo Location
        GetAllState: '/api/Location/GetAllState',
        GetAllDist: '/api/Location/GetAllDistrict/',
        GetDistDetail: '/api/Location/GetDistrictDetail',
        AddLocation: '/api/Location/InsertDistrictDetail',
    GetAreaDetail:'/api/Location/GetAreaDetail',
    GetAllArea:'/api/Location/GetAllArea/',
    GetAllStreet:'/api/Location/GetAllStreet/',
    GetStreetDetail:'/api/Location/GetAllStreetDetail',
        InsertDistrictDetail:'/api/Location/InsertDistrictDetail',

        //item master/brand
        GetAllItemDetails:'/api/Item/GetAllItemDetails',
        ItemDetailsBulkUploder:'/api/Item/ItemDetailsBulkUploder',
        GetAllBrands:'/api/Item/GetAllBrands',

        //day market
        GetDayMarketListRetailer:'/api/DayMarket/GetDayMarketList',
        GetDayMarketListPage:'/api/DayMarket/GetDayMarketListPage',
        AddMarket:'/api/DayMarket/AddMarket',
        DeleteDaYMarket:'/api/DayMarket/DeleteDaYMarket',
        SalePersonDayMarketAttach:'/api/DayMarket/SalePersonDayMarketAttach',
        SalePersonDayMarketAttachBulk:'/api/DayMarket/SalePersonDayMarketAttachBulk',

    //todo AddSalesperson
    GetAllBranch:'/api/RsmSalesperson/GetAllBranch',
    AddSalesperson:'/api/RsmSalesperson/validate_file',
    GetSalepersonDetail:'/api/RsmSalesperson/GetSalespersondata',
    DeleteSaleperson:'/api/RsmSalesperson/DeleteSaleperson',
    GetRsmDetail:'/api/RsmSalesperson/GetRsm?branch=',
        GetAssignedBrandNew:'/api/AssignBrand/GetAssignedBrand',
        GetAllBrandNew:'/api/Retailer/GetAllBrand?saleman_id=',
        AssignBrandNew:'/api/AssignBrand/AssignBrand',
        AssignBrandBulk:'/api/AssignBrand/AssignBrandBulk',
        DeleteAssignedBrands:'/api/AssignBrand/DeleteAssignedBrands',

    //Expence
    ExpenseMstCreate:'/api/Expense/ExpenseMstCreate',
        ExpenseUnpaidDocumentGet:'/api/Expense/ExpenseUnpaidDocumentGet?type=',
        ExpenseDocumentPaidMark:'/api/Expense/ExpenseDocumentPaidMark',

    //Check-in/out
    CheckInHeaderGet:'/api/Travel/CheckInHeaderGet',
    CurrentRunningCheckInData:'/api/Travel/CurrentRunningCheckInData',

        //Check-in/out
        ExpenseHeaderGetList:'/api/Expense/ExpenseHeaderGetList',
        ExpenseHeaderCreate:'/api/Expense/ExpenseHeaderCreate',

        //UserRoute
        GetDateWiseUserCoordinate:'/api/Travel/GetDateWiseUserCoordinate',

    //Grade
    GradeGetInsert:'/api/Master/GradeGetInsert',

    //Bulk report
    LikeDislikeReport:'/api/Report/LikeDislikeReport',
        CheckInExpenseReport:'/api/Report/CheckInExpenseReport',

    //Vehile
    VehileTypeGetInsert:'/api/Master/VehileTypeGetInsert',
    VehicleTypeInsertBulk:'/api/Master/VehicleTypeInsertBulk',
    VehicleRateGetInsert:'/api/Master/VehicleRateGetInsert',
    VehicleTypeSetupGetInsert:'/api/Master/VehicleTypeSetupGetInsert',
        GradeWiseLodgingCreate:'/api/Master/GradeWiseLodgingCreate',

    //todo Retailer
    GetSalesman:'/api/Retailer/GetSalesman/',
    GetRsm:'/api/Retailer/GetRsm',
    GetDistributors:'/api/Retailer/GetDistributors/',
    AddRetailer:'/api/Retailer/AddRetailer',
        AddRetailerManual:'/api/Retailer/AddRetailerManual',
    UpdateRetailer:'/api/Retailer/UpdateRetailer',
    GetAllBrand:'/api/Retailer/GetAllBrand',
    AddretailerbyExcel:'/api/Retailer/AddRetailerbyExcel',
    GetRetailerDetails:'/api/Retailer/GetRetailerDetails',
    GetRetailerDetailsById:'/api/Retailer/GetRetailerDetailsById?retailer_id=',

    //todo Distributors
    GetDistributorsDetails:'/api/RsmSalesperson/GetDistributorDetails',

    //todo AssignAreaBrandProduct
    AssignArea:'/api/AssignArea/AssignArea',
    AssignBrand:'/api/AssignArea/AssignBrand',
    GetBrand:'/api/AssignArea/GetBrand?emp_id=',
    GetStateRsm:'/api/AssignArea/GetStatetoRsm?emp_id=',
    AreaTosp:'/api/AssignArea/GetAreaTosalesman',
    retailerToSp:'/api/AssignArea/GetReatilaerToSp',
    GetAssignedState:'/api/AssignArea/GetAssignedState/',
    DeleteAssignedArea:'/api/AssignArea/DeleteAssignedArea',
    GetAssignedBrand:'/api/AssignArea/GetAssignedBrand/',
    DeleteAssignedBrand:'/api/AssignArea/DeleteAssignedBrands',
    AssignAreaToSp:'/api/AssignArea/AssignAreaToSp',
    GetAssignAreaToSp:'/api/AssignArea/GetAssignedAreaToSp/',
    GetRetailer:'/api/AssignArea/GetRetailer/',
    Assignretailer:'/api/AssignArea/Assignretailer',
    GetAssignRetailer:'/api/AssignArea/GetAssignedRetailer/',
    GetDistributorList:'/api/AssignArea/GetDistributor',
    GetAssignAreaToDistributor:'/api/AssignArea/GetAssignedAreaToDistributor/',
    GetAssignBrandToDistributor:'/api/AssignArea/GetAssignedBrandToDistributor/',
    DeleteAssignAreaToDist:'/api/AssignArea/DeleteAssignedAreaTodistributor',
    DeleteAssignRetailer:'/api/AssignArea/DeleteAssignedRetailer',
    GetRetailerToAssignSp:'/api/AssignArea/GetReatilaerToAssignSp?area_id=',
    GetRetailerToAssignDist:'/api/AssignArea/GetReatilaerToAssignDist?area_id=',

    //Todo assign Day/Market
    AddDayMarket:'/api/RsmSalesperson/AddMarket',
    AssignDayMarket:'/api/RsmSalesperson/AssignMarketToSp',
    GetDayMarketList:'/api/RsmSalesperson/GetDayMarketList',
    GetArea:'/api/AssignArea/GetAreaTosp/',
    GetAssignedDayMarket:'/api/RsmSalesperson/AssignedDayMarketList?sp_id=',
    DeleteDayMarket:'/api/RsmSalesperson/DeleteDaYMarket',
    DeleteAssignedMarket:'/api/RsmSalesperson/Deleteassignedmarket',
    GetAssignedStateToDayAssign:'/api/RsmSalesperson/GetAssignedState?emp_id=',
    GetAssignedDistToDayAssign:'/api/RsmSalesperson/GetAssignedDist',
    GetAssignAreaToDayAssign:'/api/RsmSalesperson/GetAssignedArea',
    GetAssignedRetailerToDayAssign:'/api/RsmSalesperson/GetRetailerToAssignDay',
    AssignedDayMarketList: '/api/RsmSalesperson/AssignedDayMarketList',

        //Todo Order
    GerOrderHeader:'/api/Order/GetOrderHeaderDetail',
    GetOrderimage:'/api/Order/GetOrderimage?order_no=',
    GetDistributor:'/api/Order/GetOrderDistributor?salesman=',
    GetItemGroup:'/api/Order/GetBrandItemgroup?brand_id=',
    GetItemColor:'/api/Order/GetBrandColor?itemgroup=',
    createOrder:'/api/Order/createOrder',
    GetOrderLine:'/api/Order/GetOrderLine?order_no=',
    DeleteOrderLine:'/api/Order/DeleteOrderLine',
    GetArticle:'/api/Order/GetArticle',
    GetRetailerBranchWise:'/api/Order/GetretailerBranchWise',
    GeTDistributorBranchWise:'/api/Order/GetDistributorBranchWise?branch=',
    GetOrderDetailBranchWise:'/api/Order/GetOrderDetailBranchwise?branch=' ,
    DeleteSaleOrder:'/api/Order/deleteSaleOrder',
	AddToOrder:'/api/Order/AddToOrder',
    GetTempOrderLine:'/api/Order/GettempOrderLine?order_no=',
    DeleteTempOrderLine:'/api/Order/DeleteTempOrderLine',
   AddToOrderByExcel:'/api/Order/AddToOrderByExcel',

    //Todo BranchOrder
    GetDistributorBranchOrder:'/api/Order/GetDistributorOrderBranch?branch=',
    UploadBranchOrder:'/api/Order/UploadBranchOrder',
    GetBranchOrderDetail:'/api/Order/GetBranchOrderDetail',
    GetItemMaster:'/api/Order/GetItemMaster',

    //Todo MyntraOrder
    UploadMyntraOrder:'/api/Order/UploadMyntraOrder',
    GetMyntraOrderDetail:'/api/Order/GetMyntraOrderDetail',

    //Todo UserSetup
    GetSalesperson:'/api/UserSetup/GetSalesperson',
    GetDistList:'/api/UserSetup/GetDistributor?branch=',
    Createuserpassword:'/api/UserSetup/CreateUserPassword',
        UserBlockUpdate:'/api/User/UserBlockUpdate',
    GetUserList:'/api/UserSetup/GetUserList',
    DeleteUser:'/api/UserSetup/DeleteUser',
    GetUserRole:'/api/UserSetup/GetUserRole',
    ChangeUserPassword:'/api/UserSetup/ChangeUserPassword',

    //Todo Dispatch Order
    GetOrderDetail:'/api/DispatchOrder/GetOrderDetail',
    CreateDispatchOrder:'/api/DispatchOrder/createDispatchOrder',
    ShowDispatchedOrder:'/api/DispatchOrder/showDispatchOrderDetail',
    ShowDispatchedOrderline:'/api/DispatchOrder/showDispatchOrderLine?order_no=',
    GetBoxId:'/api/DispatchOrder/GetBoxid',
    ShowItemDetail:'/api/DispatchOrder/showItemDetails?barcode=',
    ScanItem:'/api/DispatchOrder/ScanItem',
    GetPackingSlipreportData:'/api/DispatchOrder/GetPackingslipData',
    ShowDispatchLineToScan:'/api/DispatchOrder/showDispatchOrderLineToScan?order_no=',
    ShowScanneditem:'/api/DispatchOrder/showScanneditem?order_no=',
    AddToOrderForDispatch:'/api/DispatchOrder/AddToOrderForDispatchOrder',
    CompleteScanProcess:'/api/DispatchOrder/CompleteScanProcess',
        ScanItemUpload:'/api/DispatchOrder/ScanItemUpload',
    GetPickListReport:'/api/DispatchOrder/GetPickListData?order_no=',
    GetBoxIDtoprintreport:'/api/DispatchOrder/GetboxIdtoReport?order_no=',
    GetOrderItemdetail:'/api/DispatchOrder/OrderItemDetails?order_no=',
    GetScanCompltedHeader:'/api/DispatchOrder/showScanCompletedHeader',
    GetScanCompletedLine:'/api/DispatchOrder/showScanCompletedLine?order_no=',
    GetDistributorlistBranchWise:'/api/DispatchOrder/distributorlistBranchWise',
    ApproveDispatchOrder:'/api/DispatchOrder/ApproveDispatchOrder',
    ShowTotalScannedItem:'/api/DispatchOrder/showTotalScanneditem?order_no=',
    ShowDateWiseOrerNO:'/api/DispatchOrder/showOrderNoDateWise?distributor=',
    GetExistingBoxId:'/api/DispatchOrder/GetExistingBoxid?order_no=',
    GetAllDistBranchWise:'/api/DispatchOrder/AllDistBranchWise',
    DeleteScannedLineQty:'/api/DispatchOrder/DeleteScannedItemLineQty',
    ShowAllDispatchOrderDetail:'/api/DispatchOrder/showAllDispatchOrderDetail',
    DistributorListForOrder:'/api/DispatchOrder/DistibutorListForShowOrder',
    GetRetailerOrderNO:'/api/DispatchOrder/GetRetailerOrderNO?order_no=',
    GetItemGroupForPicklist:'/api/DispatchOrder/getItemGroupForPickList',
    ExcelExportDispatchOrderData:'/api/DispatchOrder/ExcelExportDispatchOrderData',
        DistributorList: '/api/Retailer/DistributorList',

    // todo Report
      TotalExcelReport:'/api/Report/GetTotalExcelReport',
      StockTakingExcelReport:'/api/Report/GetStockTackingReport',
      DailySalespersonReport:'/api/Report/GetDailySalemanReport',
      PendingDispatchReport:'/api/Report/GetPendingDispatchReport',
      GetDistributorSalesmanWise:'/api/Report/GetDistributorSalesmanWise?salesman_id=',
      GetAreaDistributorwise:'/api/Report/GetAreaDistributorWise?distributor=',
      GetRetailerWiseReportData:'/api/Report/GetRetailerWiseReportData',
      GetMonthlyScheduleVisitReportForWeb:'/api/Report/MonthlyScheduleVisitReportForWeb?month=',
       GetAllSalesmanCommisionReport:'/api/Report/GetAllSalesmanCommisionReport',
       getLocation:'/api/Report/GetLocation',
       getPendingreport:'/api/Report/Pendingreport?order_no=',

    // todo target
      GetAssignedDay:'/api/AppActivity/GetAssignedMarkettosp',
      GetAssignArea:'/api/AppActivity/GetArea',
      GetAssignedRet:'/api/AppActivity/GetRetailer',
      TargetSetToRetailer:'/api/Report/TargetSetRetailer',
      GetPeriodForAssignedTarget:'/api/Report/GetPeriodToShowtargetData',
      GetTargetData:'/api/Report/GetTargetdata',
     //todo goodsreturn
       CreateGRP:'/api/GoodsReturn/CreateGoodsReturn',
       ScanGRP:'/api/GoodsReturn/ScanGoodsReturn',
        GetGoodsReturnLine: '/api/GoodsReturn/GetGoodsReturnLine?order_no=',
        CompleteGoodsReturn: '/api/GoodsReturn/CompleteGoodsReturn',
        GetGoodsReturnForApproval: '/api/GoodsReturn/GetGoodsReturnheaderforapproval',
        ApproveGoodsReurn: '/api/GoodsReturn/ApproveGoodsReturnOrder',
        GetItemInfo: '/api/GoodsReturn/GoodsReturnItemInfo?barcode=',
        DeleteGoodsReturnLine: '/api/GoodsReturn/DeleteGoodsReturnLine',
        GetGoodsReturnDetails: '/api/GoodsReturn/GetGoodsReturnDetails?branch=',
        GetDistOrderWise: '/api/GoodsReturn/GetDistOrderWise?branch=',
        ExcelExportGoodsReturn: '/api/GoodsReturn/GoodsReturnExcelReport?order_no=',
        findItem: '/api/GoodsReturn/FindItem?name_or_no=',
        findInvoice: '/api/GateEntry/CheckInvoice?invoice=',

        //todo stocktaking
        CreateStockTakingProcess: '/api/StockTaking/CreateStockTaking',
        ScanStockTaking: '/api/StockTaking/StockTakingScaning',
        GetStockline: '/api/StockTaking/GetStockLine?order_no=',
        CompleteStockTaking: '/api/StockTaking/StockTakingComplete',
        StockTakingApproval: '/api/StockTaking/StockTakingApproval',
        GetStockTakingHeaderForApproval: '/api/StockTaking/GetStocktakingheaderforapproval?email=',
        GetStockTakingDetails: '/api/StockTaking/GetStockTakingDetails?branch=',

       //todo gate entry
       CreateGateEntry:'/api/GateEntry/CreateGateEntry',
       GetPoNumber:'/api/GateEntry/Get_Po_From_nav',
       GetVendor:'/api/GateEntry/Get_Vendor_From_nav',
       GetEntryList:'/api/GateEntry/AllGateEntryList',

    // todo arjun
    GetAllBrandMaster:'/api/OrderCreation/GetAllBrandMaster',
    InsertItemImage:'/api/OrderCreation/InsertItemImage',
    UpdateItemDiscount_Inventory:'/api/OrderCreation/UpdateItemDiscount_Inventory',
    GetDistributorDetails:'/api/OrderCreation/GetDistributorDetails?branchname=',
    UpdateSalePersonDistributer:'/api/OrderCreation/UpdateSalePersonDistributer',
    GetDiscountData:'/api/OrderCreation/GetDiscountData',
    InsertDiscountHeaderLines:'/api/OrderCreation/InsertDiscountHeaderLines',
    getItemCodeVerification:'/api/OrderCreation/getItemCodeVerification?itemcode=',
    getAllColorFromItem:'/api/OrderCreation/getAllColorFromItem',
    Update_Item_Color:'/api/OrderCreation/Update_Item_Color',
    Get_All_Item_With_Color:'/api/OrderCreation/Get_All_Item_With_Color',
    Insert_All_Item_With_Color_Image:'/api/OrderCreation/Insert_All_Item_With_Color_Image',
    GetDistributorDetails_Branch:'/api/OrderCreation/GetDistributorDetails_Branch',
    update_branch_wise_stock_by_nav:'/api/OrderCreation/update_branch_wise_stock_by_nav',
    update_shelf_inventory_upload:'/api/OrderCreation/update_shelf_inventory_upload',
    getDistributer_inventory_excel:'/api/OrderCreation/getDistributer_inventory_excel?distributor_code=',
    getOrderDetailAdmin: '/api/OrderCreation/getOrderDetailAdmin',
    get_branch_distributer_dropdown: '/api/OrderCreation/get_branch_distributer_dropdown?flag=',

       //Todo Barcode
    createDocument:'/api/BarcodePrint/CreateOrderHeader',
    getDocumentNo:'/api/BarcodePrint/GetDocumentNo?user_name=',
    getDocumentDetails:'/api/BarcodePrint/GetDocumentDetails?document_no=',
    scanItem:'/api/BarcodePrint/BarcodePrintScaning',
    updateDocumentsDetails:'/api/BarcodePrint/updateOrderDetails',
    getBarcodeReport:'/api/BarcodePrint/BarcodeReport?order_no=',
    deleteDocuments:'/api/BarcodePrint/DeleteOrder',
    getProdDocNo:'/api/BarcodePrint/Get_Prod_document_from_nav',
    getProdBarcodeReport:'/api/BarcodePrint/Get_Prod_data_From_nav?prod_no='
  }

  constructor(private httpClient:HttpClient,private globalPostingHeader:GlobalPostingHeader) { }

    ngOnInit(): void {
    }


  async PostDataToServer(path:string,json:any):Promise<any>{
    try{
      path=this.globalurl+path;
      var header=this.globalPostingHeader.getHTTPHeader;
      return  await new Promise<any>((resolve,reject) => {
        this.httpClient.post<any>(path,JSON.stringify(json),header).toPromise()
            .then(result=>resolve(result),error=>reject({
              condition:'false',
              message:error.message
            })).catch(error=>reject({ condition:'false',
            message:error.message}))
      })
    }catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition:'false',message:e.message})
      })
    }
  }

  //todo For formdata
  async Post(path:string,formdata:any):Promise<any>{
    try{
      path=this.globalurl+path;
      var header=this.globalPostingHeader.getHTTPHeader;
      return  await new Promise<any>((resolve,reject) => {
        this.httpClient.post<any>(path,formdata).toPromise()
            .then(result=>resolve(result),error=>reject({
              condition:'false',
              message:error.message
            })).catch(error=>reject({ condition:'false',
          message:error.message}))
      })
    }catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition:'false',message:e.message})
      })
    }
  }

  async GetDataFromServer(path:string):Promise<any>{
    try {
      path=this.globalurl+path;
      var header=this.globalPostingHeader.getHTTPHeader;
      return await new Promise<any>((resolve, reject) =>{
        this.httpClient.get(path,header).toPromise()
            .then(result=>resolve(result),error=>reject({
              condition:'false',
              message:error.message
            })).catch(error=>reject({condition:'false',message:error.message}))
      })
    }catch (e) {
      return  new Promise<any>((resolve) =>{
        resolve({
          condition:'false',
          message:e.message
        })
      })
    }
  }

    Get_Data_With_DownloadStatus_GetFile(path: string) {
        path = this.globalurl + path;
        const request = new HttpRequest('GET', path, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders({'Content-Type':'application/json'})
        });
        return this.httpClient.request(request);
    }
    Post_Data_With_DownloadStatus_GetFile(path: string,jsondata: any) {
        path = this.globalurl + path;
        const request = new HttpRequest('Post', path, jsondata, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders({'Content-Type':'application/json'})
        });
        return this.httpClient.request(request);
    }

    blobToString(b) {
        var urldata, x;
        urldata = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', urldata, false); // although sync, you're not fetching over internet
        x.send();
        URL.revokeObjectURL(urldata);
        return x.responseText;
    }
}
