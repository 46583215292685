<div class="navbar-top">
    <div class="logo">
        <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>
        </div>&nbsp;&nbsp;
        <img class="logo-icon" style="width: 120px" (click)="goToDashboard()" src="assets/images/logos/pristine_full_logo.png">
        <span class="logo-text secondary-text"> <img (click)="goToDashboard()" src="assets/images/logos/mylogo.png" style="width: 40px;height: 40px;"/></span>

        <!--        <span class="logo-text secondary-text">PRISTINE</span>-->
    </div>

    <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>

</div>

</div>


<div class="navbar-scroll-container" [ngClass]="'navbarscrollcontainer'"
     pristinePerfectScrollbar [pristinePerfectScrollbarOptions]="{suppressScrollX: true}">

<!--    <div class="user" fxLayout="column" [ngClass]="'bavbarscrollHeader'">-->

<!--&lt;!&ndash;        <div class="h3 username">Charlie Adams</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="avatar-container" [ngClass]="pristineConfig.layout.navbar.primaryBackground">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="avatar" src="assets/images/avatars/person_aa.svg">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--    </div>-->

    <div class="navbar-content">
        <pristine-navigation class="material2" layout="vertical"></pristine-navigation>
    </div>

</div>
<mat-divider></mat-divider>
<div class="m-0 p-4 " style="background: white">
    <div class="my_about_us_row p-8"  fxLayout="row" fxLayoutAlign="center center">

        <img class="about_us_icon" src="assets/images/logos/mylogo.png" style="width: 35px;height: 35px;">
        &nbsp;&nbsp;
        <span class="about_us_text"><a href="https://pristinebs.com" target="_blank">Powered By : <b> Pristine</b></a></span>

    </div>
</div>
