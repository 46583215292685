import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PristineConfigService} from '@pristinebase/services/config.service';
import {PristineNavigationService} from '@pristinebase/components/navigation/navigation.service';
import {PristineSidebarService} from '@pristinebase/components/sidebar/sidebar.service';
import {PristineSplashScreenService} from '@pristinebase/services/splash-screen.service';
import {PristineTranslationLoaderService} from '@pristinebase/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationTurkish} from 'app/navigation/i18n/tr';
import {SwUpdate} from "@angular/service-worker";
import {WebApiHttp} from "../@pristinebase/Process/WebApiHttp.service";
import {Resolve, Router} from "@angular/router";
import {EncriptDecript} from "../@pristinebase/Process/EncriptDecript";
import {receiverData} from "./Model/SignalRModel";
import {SignalR} from "../@pristinebase/Process/SignalR";

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    pristineConfig: any;
    navigation: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    loadingmsg:string[]=["Please Wait Loading...","It can take some more time ! Please Wait","Still waiting.."]
    message:Array<string>=[];
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _pristineConfigService: PristineConfigService,
        private _pristineNavigationService: PristineNavigationService,
        private _pristineSidebarService: PristineSidebarService,
        private _pristineSplashScreenService: PristineSplashScreenService,
        private _pristineTranslationLoaderService: PristineTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private swUpdate: SwUpdate,
        private webApiHttp: WebApiHttp,
        private _router: Router,
        private _encriptDecript:EncriptDecript,
        private _signalR: SignalR
    ) {
        // Get default navigation
        //this.navigation = navigation;

        // Register the navigation to the service
        //   this._pristineNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        // this._pristineNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._pristineTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');


        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');



        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();


        //TODO go to login page when use is unauthorized..
        try {
            let userEmail = new EncriptDecript().decrypt(localStorage.getItem('Belle_SSID').toString());
            if (userEmail == null || userEmail == '' || userEmail == undefined || userEmail == '  ')
                this._router.navigateByUrl('/pages/auth/login-2');
            else {
                this._pristineNavigationService.register('main', JSON.parse(this._encriptDecript.decrypt(localStorage.getItem('Belle_menu'))));
                this._pristineNavigationService.setCurrentNavigation('main');
                this._signalR.startConnection(userEmail, this.webApiHttp.globalurl + this.webApiHttp.ApiUrlArray.signalRNotification);
                receiverData.subscribe(result => {
                    //this.ResponseHitFromServer(result);
                    // console.log(result)
                });
            }
        } catch (e) {
            this._router.navigateByUrl('/pages/auth/login-2');
        }
    }
    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        // Do something
        //console.log('sss',sessionStorage.getItem('SSID'),localStorage.getItem('SSID'))
        if(sessionStorage.getItem('Belle_SSID')!=localStorage.getItem('Belle_SSID')){
            if(localStorage.getItem('Belle_SSID')!=null && localStorage.getItem('Belle_SSID')!='' && localStorage.getItem('Belle_SSID')!=undefined){
                sessionStorage.setItem('Belle_SSID',localStorage.getItem('Belle_SSID'))
            }else{
                sessionStorage.clear();
                localStorage.clear();
            }
            window.location.reload();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to config changes
        this._pristineConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.pristineConfig = config;

                // Boxed
                if (this.pristineConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.pristineConfig.colorTheme);
            });
        if (this.swUpdate.isEnabled) {

            this.swUpdate.available.subscribe(() => {

                if (confirm("New version available. Load New Version?")) {
                    window.location.reload();
                }
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._pristineSidebarService.getSidebar(key).toggleOpen();
    }
}
